import React, { type PropsWithChildren, useEffect, useState } from 'react';
import { Link, router } from '@inertiajs/react';
import Banner from '@/Components/Organisms/Banner';
import NavigationMenu from '@/Components/Organisms/NavigationMenu';
import { cn } from '@/Lib/utils';
import ApplicationLogo from '@/Components/Atoms/ApplicationLogo';
import useRoute from '@/Hooks/useRoute';
import { MenuIcon } from '@/Components/Icons';

interface Props {
  hasHeader?: boolean;
  fullWidth?: boolean;
  limitedWidth?: boolean | string;
  hideChat?: boolean;
}

export default function AppLayout({
  hasHeader,
  fullWidth,
  limitedWidth,
  hideChat = false,
  children,
}: PropsWithChildren<Props>) {
  const route = useRoute();
  const [showingNavigationMenu, setShowingNavigationMenu] = useState(false);

  useEffect(() => {
    document.body.classList.toggle('hide-conversation', hideChat);
  }, [hideChat]);

  router.on('navigate', () => {
    setShowingNavigationMenu(false);
  });

  return (
    <div
      className={cn(
        "layout-container grid min-h-screen grid-rows-[auto_auto_1fr] bg-background transition-all [grid-template-areas:'header-logo_header-logo''header_header''content_content'] md:m-3 md:grid-rows-[auto_1fr] md:gap-y-3 md:[grid-template-areas:'nav_header''nav_content']",
        !fullWidth ?
          'gap-x-3 md:grid-cols-[160px_1fr] lg:grid-cols-[190px_1fr]'
        : 'md:grid-cols-[0px_1fr] lg:grid-cols-[0px_1fr]',
      )}
    >
      <div className="h-15 bg-primary p-3 text-center [grid-area:header-logo] md:hidden">
        <button
          onClick={() => {
            setShowingNavigationMenu(!showingNavigationMenu);
          }}
          className="relative float-left h-full text-primary-foreground transition ease-in-out hover:text-primary-foreground-accent"
        >
          <MenuIcon
            className="absolute top-0 h-full transition"
            open={showingNavigationMenu}
            defaultSize={true}
          />
        </button>
        <Link href={route('dashboard')} className="inline-block justify-center">
          <ApplicationLogo className="h-9" />
        </Link>
      </div>
      <div
        className={cn(
          'flex h-24 items-center rounded-b-md bg-primary-foreground text-primary shadow [grid-area:header] md:mb-0 md:rounded-lg md:bg-primary md:p-6 md:text-primary-foreground',
          { hidden: !hasHeader },
          'overflow-x-auto overflow-y-hidden',
        )}
        id="header"
      ></div>
      <NavigationMenu
        className={cn(
          'fixed left-0 m-2 grid overflow-x-hidden rounded-lg bg-primary shadow ring-2 ring-ring-transparent ring-offset-2 transition-all ease-out [grid-area:nav] md:relative md:m-0 md:grid md:w-auto max-md:top-15',
          { 'max-md:-left-1/2': !showingNavigationMenu },
          { 'md:-left-4': !!fullWidth },
        )}
      />
      <main
        className={cn('relative mt-3 overflow-auto pb-3 [grid-area:content] md:-mb-3 lg:px-4', {
          'md:-mt-3': !hasHeader,
          'md:mt-0': hasHeader,
        })}
        onClick={(event) => {
          if (showingNavigationMenu) {
            event.stopPropagation();
            event.preventDefault();
            setShowingNavigationMenu(false);
          }
        }}
      >
        <div
          className={cn('absolute bottom-0 left-0 right-0 top-0 md:hidden', {
            hidden: !showingNavigationMenu,
          })}
        />
        <Banner />
        {/* <!-- Page Content --> */}
        <div
          className={cn(
            'mx-auto flex min-h-full flex-col',
            !fullWidth && '2xl:px-6',
            { 'max-w-7xl': limitedWidth === true },
            typeof limitedWidth === 'string' && limitedWidth,
          )}
        >
          {children}
        </div>
      </main>
    </div>
  );
}
