import './bootstrap';
import '../css/app.css';

import './instrument';
import React, { type ReactElement } from 'react';
import { createRoot } from 'react-dom/client';
import './i18n';
import { createInertiaApp } from '@inertiajs/react';
import { RouteContext } from '@/Hooks/useRoute';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/fr';
import AppLayout from '@/Layouts/AppLayout';
import * as Sentry from '@sentry/react';
import { type Auth } from '@/types';
import Root from '@/Root';

import.meta.glob(['../images/**', '../fonts/**']);

dayjs.extend(localizedFormat);
dayjs.locale('fr');

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'renOdit';

void createInertiaApp({
  title: (title) => `${title} - ${appName}`,
  progress: {
    color: '#4B5563',
  },
  resolve: async (name) => {
    const page = await resolvePageComponent(
      `./Pages/${name}.tsx`,
      import.meta.glob<ReactElement & { default: { layout: ReactElement } }>('./Pages/**/*.tsx'),
    );

    page.default.layout =
      page.default.layout ||
      ((page: any) => (
        <Root title={page.type.title}>
          {!name.startsWith('Auth/') ?
            <AppLayout
              hasHeader={page.type.hasHeader}
              fullWidth={page.type.fullWidth}
              limitedWidth={page.type.limitedWidth}
              hideChat={!!page.type.hideChat}
            >
              {page}
            </AppLayout>
          : page}
        </Root>
      ));

    return page;
  },
  setup({ el, App, props }) {
    const user = (props.initialPage.props.auth as Auth).user;
    if (user) {
      Sentry.setUser({ id: user.id, email: user.email, username: user.name });
    }

    const root = createRoot(el);
    root.render(
      <RouteContext.Provider value={(window as any).route}>
        <App {...props} />
      </RouteContext.Provider>,
    );
  },
});
