import React, { type PropsWithChildren, useEffect } from 'react';
import { Head, router } from '@inertiajs/react';
import { Toaster } from '@/Components/Organisms/Toaster';
import { TooltipProvider } from '@/Components/Atoms/Tooltip';
import { Auth } from '@/types';
import * as Sentry from '@sentry/react';

interface Props {
  title: string;
}

export default function Root({ title, children }: PropsWithChildren<Props>) {
  useEffect(() => {
    return router.on('success', (event) => {
      if (event.detail.page.props.flash === 'loggedIn') {
        let user = (event.detail.page.props.auth as Auth).user;

        Sentry.setUser({ id: user.id, email: user.email, username: user.name });

        if ((window as any).BrevoConversationsID) {
          (window as any).BrevoConversations('updateIntegrationData', {
            email: user.email,
            firstName: user.firstname,
            lastName: user.lastname,
          });

          // Update the settings
          (window as any).BrevoConversationsSetup.visitorId = user.visitorId;

          // Restart Brevo Conversations
          (window as any).BrevoConversations('restart');
        }
      }
    });
  }, []);

  return (
    <TooltipProvider delayDuration={100}>
      <Head title={title} />
      {children}
      <Toaster />
    </TooltipProvider>
  );
}
