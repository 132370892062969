const translations: Record<string, string> = {
    Study: 'Accompagnement',
    Wall: 'Murs',
    Roof: 'Toiture',
    Floor: 'Plancher',
    Opening: 'Ouvrants',
    Ventilation: 'Ventilation',
    Heating: 'Chauffage',
    HeatingBackup: "Chauffage d'appoint",
    HotWater: 'Eau chaude sanitaire',
    'project:read': "Lecture d'un projet",
    'project:write': "Création / modification d'un projet",
    'project:delete': "Suppression d'un projet",
};

export default translations;
