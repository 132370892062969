import React from 'react';
import SVGIcon, { type IconProps } from '@/Components/Icons';

export default function HeatPumpIcon(props: IconProps) {
  return (
    <SVGIcon {...props}>
      <path
        fill="currentColor"
        d="M10.6 16.95q.325.275.688.388t.712.112q.725 0 1.313-.462T14 15.65q.125-1.175-.725-1.737T12 12.45q-.125.35-.125.65t.075.65q.075.425.175.8t.025.8q-.125.45-.55.925t-1 .675M2 20V4q0-.825.588-1.412T4 2h16q.825 0 1.413.588T22 4v16q0 .825-.587 1.413T20 22H4q-.825 0-1.412-.587T2 20m10-2q1.25 0 2.125-.875T15 15q0-.6-.25-1t-.7-.75q-.95-.675-1.588-1.412T11.45 10.35q-1.1.875-1.775 1.988T9 14.95q0 .875.9 1.963T12 18m-8 2h2v-1q0-.425.288-.712T7 18h1.25q-.575-.725-.913-1.525T7 14.95q0-2.625 1.2-4.212t3.375-2.888q.25-.15.5-.15t.475.125t.375.35t.175.5q.05.85.725 1.65T15.15 11.6q.825.6 1.338 1.413T17 15q0 .875-.275 1.613T16 18h1q.425 0 .713.288T18 19v1h2V4H4zm8-2"
      />
    </SVGIcon>
  );
}
